import ModeToggle from "./components/ModeToggle";
import ArticleDetail from "./components/ArticleDetail";
import ArticleList from "./components/ArticleList";
import About from "./components/About";
import AdminPage from './components/AdminPage';
import NewArticle from './components/NewArticle';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './components/LoginPage';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import React from 'react';


function App() {
  const location = useLocation();


  // Apply different background based on the path
  const isAboutPage = location.pathname === "/about";
  const backgroundClass = isAboutPage ? 'bg-gif' : '';
  const backgroundOverlay = isAboutPage ? 'bg-overlay' : '';
  // const fonts = isAboutPage ? 'press-start-2p-regular' : '';
  const fontColor = isAboutPage ? 'text-slate-100' : 'text-slate-900';

  return (
    <div className={`antialiased min-h-screen bg-white dark:bg-slate-950  dark:text-slate-50 ${backgroundClass} ${fontColor} poppins-regular`}>
      <div className={`${backgroundOverlay} `}>
        <div className="max-w-4xl mx-auto py-10 px-4 " >
          <header>
            <div className="flex items-center justify-between">
              <ModeToggle />
              <nav className="ml-auto font-medium space-x-6">
                <Link to="/" >Home</Link>
                <Link to="https://x.com/omidxrz" target="_blank" rel="noopener noreferrer">Twitter</Link>
                <Link to="https://github.com/omidxrz" target="_blank" rel="noopener noreferrer">Github</Link>
                {/* <Link to="/rss">rss</Link> */}
                <Link to="/about">About</Link>

              </nav>
            </div>
          </header>

          <main className="mt-10">
            <Routes>
              <Route path="/" element={<ArticleList />} />
              <Route path="/about" element={<About />} />
              <Route path="/articles/:slug" element={<ArticleDetail />} />
              <Route path="/admin" element={<ProtectedRoute component={AdminPage} />} />
              <Route path="/admin/new-posts" element={<ProtectedRoute component={NewArticle} />} />
              <Route path="/login" element={<LoginPage />} />
            </Routes>
          </main>
          <footer>
            <div className="flex items-center justify-between">
              <nav className="font-medium space-x-6">
                {/* <Link to="/" >RSS</Link> */}
              </nav>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
};

export default App;