import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

// Function to check if the user is an admin
const isAdmin = async () => {
  try {
    const response = await fetch('/api/login/checkAuth', {
      method: 'GET',
    });
    if (!response.ok) throw new Error('Failed to authenticate');
    
    return true; // Assuming API returns { isAdmin: true/false }
  } catch (error) {
    console.error('Login failed', error);
    return false;
  }
};

// ProtectedRoute component
const ProtectedRoute = ({ component: Component }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await isAdmin();
      setIsAuthenticated(authStatus);
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    // Optionally, you could return a loading spinner or skeleton UI
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;