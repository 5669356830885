import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const AdminPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  
  const [posts, setPosts] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [editingPostId, setEditingPostId] = useState(null);

  // Fetch existing posts from the API

  
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://your-api-endpoint.com/posts');
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  // Handle creating or updating a post
  const handleSavePost = async (e) => {
    e.preventDefault();
    
    const newPost = { title, content };

    try {
      let response;
      if (editingPostId) {
        // Update existing post
        response = await fetch(`https://your-api-endpoint.com/posts/${editingPostId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newPost),
        });
      } else {
        // Create new post
        response = await fetch('https://your-api-endpoint.com/posts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newPost),
        });
      }

      const savedPost = await response.json();
      
      if (editingPostId) {
        setPosts(posts.map(post => (post.id === editingPostId ? savedPost : post)));
      } else {
        setPosts([...posts, savedPost]);
      }

      // Clear the form
      setTitle('');
      setContent('');
      setEditingPostId(null);

    } catch (error) {
      console.error('Error saving post:', error);
    }
  };

  // Handle editing a post
  const handleEditPost = (post) => {
    setTitle(post.title);
    setContent(post.content);
    setEditingPostId(post.id);
  };

  // Handle deleting a post
  const handleDeletePost = async (postId) => {
    try {
      await fetch(`https://your-api-endpoint.com/posts/${postId}`, {
        method: 'DELETE',
      });
      setPosts(posts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  return (
    
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Admin Dashboard</h1>
      
      {/* Post Form */}
      <div className=" shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">
          {editingPostId ? 'Edit Post' : 'Create New Post'}
        </h2>
        <form onSubmit={handleSavePost}>
          <div className="mb-4">
            <label htmlFor="title" className="block  font-medium mb-2">Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-3 py-2 border rounded-md dark:bg-slate-800"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="content" className="block font-medium mb-2">Content</label>
            <textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full px-3 py-2 border rounded-md dark:bg-slate-800"
              required
            ></textarea>
          </div>
          <div class="flex items-center mb-4">
            <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            </input>
            <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Publish</label>
          </div>
          <div class="flex items-center mb-4">
            <DatePicker
              selected={startDate} // Default is today's date
              onChange={(date) => setStartDate(date)} // Method to handle the date change
              dateFormat="dd/MM/yyyy" // Custom date format
              placeholderText="Select a date" // Placeholder text
              className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>

          {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} class="relative max-w-sm rounded-md" /> */}

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
          >
            {editingPostId ? 'Update Post' : 'Create Post'}
          </button>
        </form>
      </div>

      {/* Post List */}
      <h2 className="text-xl font-semibold mb-4">Manage Posts</h2>
      <ul className="space-y-4">
        {posts.map((post) => (
          <li key={post.id} className="bg-white shadow-md rounded-lg p-4">
            <h3 className="text-lg font-semibold">{post.title}</h3>
            <p className="text-gray-700 mb-4">{post.content}</p>
            <div className="flex space-x-2">
              <button
                onClick={() => handleEditPost(post)}
                className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-700"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeletePost(post.id)}
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminPage;