import React from "react";
import { useParams } from "react-router-dom";

// Example articles data (you can fetch this from an API or manage it globally)
const articles = [
  {
    id: 1,
    title: "Test1",
    content: "test",
    slug: "title-1",
    thumbnail: "https://via.placeholder.com/1000", // Larger placeholder thumbnail
    datePublished: "2024-10-09",
  },
  {
    id: 2,
    title: "Title 2",
    content: "This is the full content of the second article. It contains all the details about the article.",
    slug: "title-2",
    thumbnail: "https://via.placeholder.com/1000", // Larger placeholder thumbnail
    datePublished: "2024-10-08",
  },
];

// Helper function to format date
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

const ArticleDetail = () => {
  const { slug } = useParams();
  const article = articles.find(article => article.slug === slug);

  if (!article) {
    return <div>Article not found</div>;
  }

  return (
    <div className="prose dark:prose-invert max-w-4xl mx-auto mt-10">
      {/* Title with larger font size */}
      <h1 className="text-5xl font-bold mb-4">{article.title}</h1>

      {/* Date published */}
      <p className="text-1xl  text-gray-400 mb-4">
        Published on {formatDate(article.datePublished)}
      </p>

      {/* Thumbnail with larger size and some spacing */}
      <img 
        src={article.thumbnail} 
        alt={article.title} 
        className="w-full h-auto max-h-96 object-cover rounded-lg shadow-lg mb-8" 
      />

      {/* Article content */}
      <p
      >{article.content}</p>
    </div>
  );
};

export default ArticleDetail;